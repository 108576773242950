import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();
const { persistAtom: persistAtom2 } = recoilPersist();

export const isClickedSanta = atom({
  key: "isClickedSantaAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const isOpenOnceAtom = atom({
  key: "isOpenOnceAtom",
  default: false,
  effects_UNSTABLE: [persistAtom2],
});
