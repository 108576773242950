import { Button, Modal, Image, Row, Col } from "antd";
import React, { useState, useRef } from "react";
import "../styles/PrintIDCard.css";
import WorkerBackground from "../../assets/img/worker-card-bg.svg";
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";

export const PrintIdCard = ({ work_id, full_nam, position, worker_image }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Button
        type="primary"
        style={{ width: "100%" }}
        size="large"
        onClick={showModal}
      >
        ព្រីនកាត
      </Button>
      <Modal
        footer={[
          <Button
            type="primary"
            size="large"
            onClick={handlePrint}
            icon={<PrinterOutlined />}
            block
          >
            Print
          </Button>,
        ]}
        width={1000}
        title="ព្រីនកាត"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ padding: 0 }}
      >
        {/* <div></div> */}

        <div className="print-body" ref={componentRef}>
          <img
            style={{
              width: "100%",
            }}
            src={WorkerBackground}
            alt="image23-test"
          />

          {worker_image !== "" ? (
            <img
              className="worker_image"
              src={`${process.env.React_App_WORKER_PROFILE}/${worker_image}`}
            />
          ) : null}

          <div className="text-container">
            <h3 className="worker_name">{full_nam}</h3>
            <p className="worker_position">{position}</p>
            <p className="worker_id">លេខកូដ: {work_id}</p>
          </div>
          {/* <div className="worker_ID_Image">
                <div className="worker_Image">
                  
                </div>
                
                <p className="worker_position">{position}</p>
                <p className="worker_id">លេខកូដ៖ {work_id}</p>
              </div> */}
        </div>
        {/* <div className="PrintIdCard-container" ></div> */}
      </Modal>
    </>
  );
};
