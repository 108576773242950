import React, { memo, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import KhmerOSSiemreap from "../../assets/fonts/KHMEROSSIEMREAP.TTF";
import { useRequest } from "ahooks";
import { getCustomerProfitExpenseGraph } from "../../getDatabase";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  responsive: true,
  maxBarThickness: 50,
  plugins: {
    // legend: {
    //   labels: {
    //     font: {
    //       size: 15,
    //       family: KhmerOSSiemreap,
    //     },
    //   },
    // },
    legend: false,
    title: {
      display: true,
      text: "ចំណូលចំណាយ",
      font: {
        size: 15,
        family: KhmerOSSiemreap,
      },
    },
    datalabels: {
      anchor: "start",
      offset: -20,
      align: "start",
      //   color: "black", // Color of the labels
      //   font: {
      //     weight: "bold",
      //   },
    },
  },
  scales: {
    y: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        min: 0,
        maxTicksLimit: 5,
        font: {
          family: "Khmer OS Siemreap",
        },
      },
    },
    x: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        min: 0,
        // maxTicksLimit: 7
        font: {
          family: "Khmer OS Siemreap",
        },
      },
    },
  },
};

const ProfitExpenseChart = memo(() => {
  const {
    run: fetchProfitExpense,
    loading: loadingProfitExpense,
    data: profitExpenseData,
  } = useRequest(getCustomerProfitExpenseGraph, {
    manual: true,
  });
  const array = profitExpenseData?.data || [];

  const getBarLabels = (offset = 10) => {
    if (array?.length > 10) {
      return array?.map((e) => e?.constructionName);
    }

    const newOffset = offset - array?.length;
    const emptyLabel = [...Array(newOffset)].map(() => "");

    return [...array?.map((e) => e?.constructionName), ...emptyLabel];
  };

  let chartData = {
    labels: getBarLabels(),
    datasets: [
      {
        label: "ចំណូល",
        data: array?.map((e) => e?.totalProfit),
        borderColor: "lightgreen",
        backgroundColor: "lightgreen",
        tension: 0.6,
        // borderColor: "rgba(2,117,216,1)",
        // pointRadius: 5,
        // pointBackgroundColor: "rgba(2,117,216,1)",
        // pointBorderColor: "rgba(255,255,255,0.8)",
        // pointHoverRadius: 5,
        // pointHoverBackgroundColor: "rgba(2,117,216,1)",
        // pointHitRadius: 50,
        // pointBorderWidth: 2,
      },
      {
        label: "ចំណាយ",
        data: array?.map((e) => e?.totalExpense),
        borderColor: "pink",
        backgroundColor: "pink",
        tension: 0.6,
      },
    ],
  };

  useEffect(() => {
    fetchProfitExpense();
  }, []);

  return (
    <div>
      <Bar options={options} data={chartData} />
    </div>
  );
});

export default ProfitExpenseChart;
