import React, { useEffect, useState } from "react";
import { Button, Popover, Space, Table, Typography } from "antd";
import axios from "axios";
import moment from "moment";
import {
  currencyFormat,
  getCustomerWithExpenseWithPagination,
} from "../../getDatabase";
import { useRequest } from "ahooks";
import numeral from "numeral";
import ProfitExpenseDrawer from "../customer/ProfitExpenseDrawer";
import { Link } from "react-router-dom";

const { Paragraph, Text } = Typography;
export default function LastUpdateIncomeExpense({ date }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const {
    run: runFetch,
    loading,
    data: customerData,
  } = useRequest(getCustomerWithExpenseWithPagination, {
    manual: true,
  });

  useEffect(() => {
    runFetch({
      page,
      pageSize,
    });
  }, [page, pageSize]);

  const contentRemark = (e) => (
    <Paragraph style={{ fontSize: 16, width: 300, textAlign: "justify" }}>
      {e}
    </Paragraph>
  );

  const columns = [
    {
      title: "លរ",
      dataIndex: "no",
      key: "no",
      fontWeight: "bold",
      fixed: "left",
      width: 40,
    },
    {
      title: "ឈ្មោះការដ្ឋាន",
      dataIndex: "constructionName",
      key: "constructionName",
      fixed: "left",
      width: 200,
    },

    {
      title: "ថ្ងៃចាប់ផ្ដើម",
      dataIndex: "startDate",
      key: "startDate",
      width: 100,
      render: (text, record) => (
        <span size="middle">
          {moment(record?.startDate).format("DD-MM-YYYY")}
        </span>
      ),
    },

    {
      title: "ថ្ងៃបញ្ចប់",
      dataIndex: "endDate",
      key: "endDate",
      width: 100,
      render: (text, record) => (
        <span size="middle">
          {record?.endDate !== "0000-00-00"
            ? moment(record?.endDate).format("DD-MM-YYYY")
            : "គ្មាន"}
        </span>
      ),
    },
    {
      title: "ចំណូល",
      dataIndex: "totalProfit",
      key: "totalProfit",
      width: 90,
      render: (text, record) => (
        <span size="middle">
          {numeral(record?.totalProfit).format("0,0.00")}
        </span>
      ),
    },

    {
      title: "ចំណាយ",
      dataIndex: "totalExpense",
      key: "totalExpense",
      width: 90,
      render: (text, record) => (
        <span size="middle">
          {numeral(record?.totalExpense).format("0,0.00")}
        </span>
      ),
    },
    {
      title: "ចំណេញ/ខាត",
      dataIndex: "balance",
      key: "balance",
      width: 100,
      render: (text, record) => {
        const balance =
          parseFloat(record?.totalProfit) - parseFloat(record?.totalExpense);

        const formatBalance = numeral(balance).format("(0,0.00)");

        return (
          <span
            size="middle"
            style={{
              color: Math.sign(balance) >= 0 ? "green" : "red",
            }}
          >
            ${formatBalance}
          </span>
        );
      },
      //   render: (text, record) => (
      //     <span style={{ cursor: "pointer" }}>
      //       <Popover
      //         placement="bottom"
      //         content={() => contentRemark(record?.balance)}
      //         title={null}
      //         trigger="hover"
      //       >
      //         <Text ellipsis>{record?.balance}</Text>
      //       </Popover>
      //     </span>
      //   ),
    },
    {
      key: "action",
      fixed: "right",
      align: "center",
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <ProfitExpenseDrawer
            c_id={record?.c_id}
            customer={record}
            isWritable={false}
          />
          {record?.pdfFile && (
            <Link
              to={`${process.env.React_App_PDF}/${record?.pdfFile}`}
              target="_blank"
            >
              <Button
                type={"danger"}
                shape="circle"
                size="middle"
                style={{ fontWeight: "bold" }}
              >
                PDF
              </Button>
            </Link>
          )}
          {/* <UpdateCustomer
            setSuccess={setSuccess}
            c_id={record.c_id}
            customer={record}
          />
          <DelectCustomer setSuccess={setSuccess} id={record.c_id} /> */}
        </Space>
      ),
    },
  ];

  let tableDataWithNo = [];

  customerData?.data?.map((record, index) => {
    let pageAdd = page > 1 ? page * pageSize - pageSize + 1 : 1;

    let data = {
      ...record,
      no: customerData?.totalDoc - (pageAdd + index) + 1,
    };
    tableDataWithNo.push(data);
  });
  return (
    <Table
      // size="small"
      style={{ marginBottom: 50 }}
      scroll={{ x: 1200 }}
      columns={columns}
      rowKey={(record) => record?.c_id}
      // className='Info-table'
      className='table-customize'
      loading={loading}
      dataSource={loading ? [] : tableDataWithNo}
      pagination={{
        position: ["bottomLeft"],
        size: "small",
        total: customerData?.totalDoc,
        pageSizeOptions: ["20", "50", "100"],
        pageSize: pageSize,
        onChange: (page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        },
      }}
    />
  );
}
