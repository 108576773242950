import React, { useEffect, useState } from "react";
import { Table, Space, Typography, Button, Popconfirm, Badge } from "antd";
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import UpdateWorker from "./UpdateWorker";
import { useRequest } from "ahooks";
import { deleteWorker } from "../../getDatabase";
import { currencyFormatKHR } from "../../own-comp";

const { Text } = Typography;

const tableColumns = ({ handleUpdate, handleOpenProfile, handleDelete }) => {
  const columns = [
    {
      title: "លរ",
      dataIndex: "no",
      key: "no",
      width: 50,
    },
    {
      title: "ឈ្មោះ",
      dataIndex: "full_name",
      key: "full_name",
      width: 130,
      render: (text, record) => {
        return (
          <div
            onClick={() => handleOpenProfile(record)}
            style={{ cursor: "pointer" }}
          >
            {record?.full_name}
          </div>
        );
      },
    },
    {
      title: "តួនាទី",
      dataIndex: "position",
      key: "position",
      width: 100,
    },
    {
      title: "លេខទូរស័ព្ទ",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 110,
      render: (text, record) => {
        return (
          <Space size="middle">
            <a href={`tel:${record?.phone_number}`}>{record?.phone_number}</a>
          </Space>
        );
      },
    },

    // {
    //   title: "តម្លៃចូល",
    //   width: 120,
    //   render: (text, record) => {
    //     let feeStart = record?.fees?.length > 0 ? record?.fees[0]?.fee : null;
    //     return <Space size="middle">{currencyFormatKHR(feeStart)}</Space>;
    //   },
    // },
    {
      title: "តម្លៃបច្ចុប្បន្ន",
      width: 120,
      key: "currentPrice",
      sorter: true,
      render: (text, record) => {
        let feeCurrent =
          record?.fees?.length > 0
            ? record?.fees[record?.fees?.length - 1]?.fee
            : null;
        return <Space size="middle">{currencyFormatKHR(feeCurrent)}</Space>;
      },
    },

    {
      title: "FB",
      width: 60,
      render: (text, record) => (
        <Space size="middle">
          {record?.facebook_url !== "" ? (
            <a href={record?.facebook_url} rel="noreferrer" target="_blank">
              View
            </a>
          ) : null}
        </Space>
      ),
    },
    {
      title: "ជំនាញ",
      width: 200,
      render: (text, record) => (
        <Text ellipsis={{ tooltip: record?.skills.toString() }}>
          {record?.skills.toString()}
        </Text>
      ),
    },

    {
      title: "ស្ថានភាព",
      dataIndex: "work_status",
      key: "work_status",
      width: 100,
      render: (text, record) => (
        <Text
          ellipsis={{ tooltip: record?.work_status.toString() }}
          style={{ paddingLeft: 5 }}
        >
          <Badge
            color={record?.work_status === "ធម្មតា" ? "green" : "red"}
            status={record?.work_status === "ធម្មតា" ? "processing" : ""}
          />
          {record?.work_status.toString()}
        </Text>
      ),
    },

    {
      key: "action",
      fixed: "right",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          {/* <Button
            type="primary"
            onClick={() => handleOpenProfile(record)} //navigate(`/builder/${record.w_id}`)
            shape="circle"
            icon={
              <AiFillEye className="printIcon" style={{ marginTop: "5px" }} />
            }
            size="middle"
          /> */}

          <Button
            onClick={() => handleUpdate(record)}
            type="primary"
            shape="circle"
            icon={<AiOutlineEdit style={{ marginTop: "5px" }} />}
            size="middle"
          />

          <Popconfirm
            placement="topLeft"
            title={"តើអ្នកចង់លុបមែនទេ?"}
            onConfirm={() => handleDelete(record)}
            okText="ព្រម"
            cancelText="មិនព្រម"
          >
            <Button
              type="primary"
              shape="circle"
              icon={<AiOutlineDelete style={{ marginTop: "5px" }} />}
              size="middle"
              danger
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return columns;
};

export default function BuilderBiographyTable({
  tableData,
  page,
  pageSize,
  loading,
  setPage,
  setPageSize,
  refresh,
  setSortData,
}) {
  let navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  const { run: runDelete } = useRequest(deleteWorker, {
    manual: true,
    onSuccess: () => {
      refresh();
    },
  });

  const handleOpenProfile = (record) => {
    navigate(`/builder/${record?.uu_id}`);
  };

  const handleUpdate = (record) => {
    setEditData(record);
    setOpenEdit(true);
  };

  const handleDelete = (record) => {
    runDelete({ id: record?.w_id });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.columnKey === "currentPrice") {
      setSortData((prev) => ({
        ...prev,
        currentPrice: sorter?.order,
      }));
    }
  };

  let tableDataWithNo = tableData?.data?.map((record, index) => {
    let pageAdd = page > 1 ? page * pageSize - pageSize + 1 : 1;

    let data = { ...record, no: tableData?.totalDoc - (pageAdd + index) + 1 };
    return data;
  });

  return (
    <>
      <UpdateWorker
        open={openEdit}
        setOpen={setOpenEdit}
        refresh={refresh}
        data={editData}
      />
      <Table
        columns={
          !isMobile
            ? tableColumns({
                handleOpenProfile,
                handleUpdate,
                handleDelete,
              })
            : tableColumns({
                handleOpenProfile,
                handleUpdate,
                handleDelete,
              }).filter((e) => e?.key !== "no" && e?.key !== "action")
        }
        dataSource={loading ? [] : tableDataWithNo}
        scroll={{ x: 1200 }}
        loading={loading}
        rowKey={(record) => record?.w_id}
        rowClassName={(record) =>
          record?.work_status !== "ធម្មតា" ? "finished-row" : ""
        }
        className="table-customize"
        style={{ marginTop: "20px" }}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomLeft"],
          size: "small",
          total: tableData?.totalDoc,
          pageSizeOptions: ["100", "200", "300"],
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      />
    </>
  );
}
