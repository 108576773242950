import React, { useEffect, useMemo, useState } from "react";
import {
  Drawer,
  Select,
  Input,
  DatePicker,
  Row,
  Col,
  Form,
  message,
  Switch,
  Image,
  Divider,
} from "antd";
import { Button } from "antd";
import ProfitFormList from "./ProfitFormList";
import ExpenseFormList from "./ExpenseFormList";
import { expenseNameList } from "../../constant/expense";
import { DollarCircleTwoTone } from "@ant-design/icons";
import { useRequest } from "ahooks";
import {
  currencyFormat,
  getCustomerProfitExpenseById,
  mutateCustomerProfitExpense,
} from "../../getDatabase";
import * as _ from "lodash";
import { AiFillEye, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { UploadController } from "../../own-comp";
import { Link } from "react-router-dom";

export default function ProfitExpenseDrawer({ c_id, isWritable = true }) {
  const [visible, setVisible] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const [pdfFile, setPdfFile] = useState(null);

  const [form] = Form.useForm();
  const profitList = Form.useWatch("profitList", form);
  const expenseList = Form.useWatch("expenseList", form);

  const {
    run: runFetch,
    loading: fetchLoading,
    data: profitExpenseData,
    refresh,
  } = useRequest(getCustomerProfitExpenseById, {
    manual: true,
  });

  const { run, loading } = useRequest(mutateCustomerProfitExpense, {
    manual: true,
    onSuccess: (res) => {
      const isSuccess = res?.data?.toString().trim() === "success";
      if (isSuccess) {
        message.success("បង្តើតជោគជ័យ!!");
        form.resetFields();
        setVisible(false);
        setPdfFile(null);
        refresh();
      } else {
        message.error("មានបញ្ហា!");
      }
    },
  });

  const handleResize = () => {
    // 960
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const onFinish = async (values) => {
    if (!isWritable) return;
    run({
      data: {
        ...values,
        customerId: c_id,
        showInAccountDashboard: values?.showInAccountDashboard ? 1 : 0,
        pdfFile: pdfFile,
      },
    });
  };

  useEffect(() => {
    let expenseData = expenseNameList?.map((e, index) => {
      return {
        expense_name: e?.value,
        expense: null,
      };
    });

    let profitData = [];

    if (profitExpenseData?.profitList?.length > 0) {
      profitExpenseData?.profitList?.forEach((item) => {
        profitData.push({
          ...item,
        });
      });
    }

    if (profitExpenseData?.expenseList?.length > 0) {
      const mergeObject = _.merge(
        _.keyBy(expenseData, "expense_name"),
        _.keyBy(profitExpenseData?.expenseList, "expense_name")
      );
      expenseData = Object.values(mergeObject);
    }

    form.setFieldsValue({
      profitList: profitData,
      expenseList: expenseData,
      showInAccountDashboard: profitExpenseData?.showInAccountDashboard === "1",
    });

    return () => {
      form.resetFields();
    };
  }, [visible, form, profitExpenseData]);

  useEffect(() => {
    if (c_id && visible) {
      runFetch({
        c_id,
      });
    }
  }, [visible, c_id]);

  const totalProfit = useMemo(
    () =>
      profitList?.reduce(
        (acc, curr) => acc + parseFloat(curr?.profit || "0"),
        0
      ),
    [profitList]
  );
  const totalExpense = useMemo(
    () =>
      expenseList?.reduce(
        (acc, curr) => acc + parseFloat(curr?.expense || "0"),
        0
      ),
    [expenseList]
  );

  const onSelectFile = async (e) => {
    let pdfFileData = e.target.files[0];
    if (pdfFileData?.type?.split("/")[1] === "pdf") {
      setPdfFile(pdfFileData);
    } else {
      message.error("មិនមែនជា File PDF ទេ!!");
    }
  };

  return (
    <>
      <Button
        onClick={showDrawer}
        type={isWritable ? "default" : "primary"}
        shape="circle"
        icon={
          isWritable ? (
            <DollarCircleTwoTone style={{ marginTop: "-1px" }} />
          ) : (
            <AiFillEye className="printIcon" style={{ marginTop: "5px" }} />
          )
        }
        size="middle"
      />

      <Drawer
        width={isMobile ? "100%" : 736}
        title="ចំណូលចំណាយ"
        placement="right"
        onClose={onClose}
        visible={visible}
        bodyStyle={{
          backgroundColor: "#efefef",
        }}
        headerStyle={{
          backgroundColor: "white",
        }}
      >
        <Form
          form={form}
          id="create-customer-form"
          layout="vertical"
          onFinish={onFinish}
        >
          {/* <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="landNumber"
                label="លេខប័ណ្ណដី"
                // rules={[{ required: true, message: "សូមបំពេញលេខប័ណ្ណដី!!" }]}
              >
                <Input placeholder="លេខប័ណ្ណដី" size="large" allowClear />
              </Form.Item>
            </Col>
          </Row> */}

          <Row>
            {isWritable && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="showInAccountDashboard"
                  label="បង្ហាញ"
                  valuePropName="checked"
                  labelAlign="right"
                >
                  <Switch
                    checkedChildren={<AiOutlineCheck />}
                    unCheckedChildren={<AiOutlineClose />}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "lightgreen",
                  padding: "6px 10px",
                  marginBottom: 25,
                  borderBottom: "2px solid black",
                }}
              >
                <div style={{ fontSize: 17 }}>Profit</div>
                <div style={{ fontSize: 17 }}>
                  {currencyFormat(totalProfit)}
                </div>
              </div>
              <ProfitFormList name="profitList" isWritable={isWritable} />
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "pink",
                  padding: "6px 10px",
                  marginBottom: 25,
                  borderBottom: "2px solid black",
                }}
              >
                <div style={{ fontSize: 17 }}>Expense</div>
                <div style={{ fontSize: 17 }}>
                  {currencyFormat(totalExpense)}
                </div>
              </div>
              <ExpenseFormList name="expenseList" isWritable={isWritable} />
            </Col>
          </Row>

          {isWritable && (
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Divider />
                <div
                  style={{
                    padding: "10px",
                    marginTop: "-10px",
                    textAlign: "center",
                  }}
                >
                  {/* <p>PDF</p> */}

                  <UploadController
                    name="customerImage"
                    accept="application/pdf"
                    onChange={onSelectFile}
                  >
                    Browse PDF
                  </UploadController>

                  <br />
                  <br />
                  {pdfFile && (
                    <p
                      style={{
                        fontSize: 16,
                        padding: "2px 6px",
                        borderRadius: 10,
                        background: "#DDDDDD",
                      }}
                    >
                      PDF Selected
                    </p>
                  )}
                </div>
                <Divider />
              </Col>
            </Row>
          )}
          {isWritable && (
            <div
              style={{
                display: "flex",
                gap: 10,
              }}
            >
              <div style={{ flex: 1 }}>
                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={loading}
                  >
                    បញ្ជូន
                  </Button>
                </Form.Item>
              </div>

              {profitExpenseData?.pdfFile && (
                <div>
                  {/* `${process.env.React_App_PDF}/${profitExpenseData?.pdfFile}` */}
                  <Link
                    to={`${process.env.React_App_PDF}/${profitExpenseData?.pdfFile}`}
                    target="_blank"
                  >
                    <Button
                      type={"danger"}
                      size="large"
                      style={{ fontWeight: "bold" }}
                    >
                      PDF
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          )}
        </Form>
      </Drawer>
    </>
  );
}
