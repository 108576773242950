import { Col, Row, Typography } from "antd";
import React from "react";

function TeamTmr({ team }) {
  const teamData = team?.map((e) => ({
    ...e,
    isContract: parseInt(e?.isContract, 10) > 0 ? true : false,
  }));

  return (
    <div style={{ padding: 10, background: "#ede9e9" }}>
      <center>
        <Typography.Text strong style={{ textDecoration: "underline" }}>
          ការងារសម្រាប់ថ្ងៃស្អែក
        </Typography.Text>
      </center>
      {teamData?.map((e) => {
        const totalBuilderTmr =
          parseFloat(e?.blacksmithCount) +
          parseFloat(e?.builderCount) +
          parseFloat(e?.carpenterCount) +
          parseFloat(e?.painterCount);

        const totalWorkerTmr =
          parseFloat(e?.maleWorkerCount) + parseFloat(e?.femaleWorkerCount);

        return (
          <Row key={e?.dtmr} style={{ fontSize: 11 }}>
            <Col
              xs={11}
              style={{
                display: "inline-block",
                fontWeight: "bold",
              }}
            >
              - ក្រុម{e?.teamNumber} (ជ{totalBuilderTmr || 0} ក
              {totalWorkerTmr || 0})
            </Col>
            <Col xs={1}>:</Col>
            <Col xs={12} style={{ wordWrap: "break-word" }}>
              {e?.performance}
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

export default TeamTmr;
