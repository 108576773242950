import { Button, Col, Divider, Form, Input, Row, Select, Switch } from "antd";
import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { countSelectOption } from "../../getDatabase";
import { AiFillCloseCircle, AiOutlineTeam } from "react-icons/ai";

const { TextArea } = Input;

function FormListTeam({ report, name, type, addButtonTitle }) {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => {
            return (
              <div key={key}>
                <Row gutter={10}>
                  <Col xs={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "rgb(135, 187, 237,0.6)",
                        padding: "6px 10px",
                        marginBottom: 25,
                      }}
                    >
                      <div style={{ fontSize: 20 }}>
                        {type === "create" ||
                        (report && index >= report?.length) ? (
                          <AiFillCloseCircle
                            style={{ fontSize: 18 }}
                            className="remove-class zoom-bigger"
                            onClick={() => remove(name)}
                          />
                        ) : null}{" "}
                        <AiOutlineTeam /> {`ក្រុមទី៖ ${index + 1}`}
                      </div>

                      <div
                        style={{
                          fontSize: 18,
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "isContract"]}
                          fieldKey={[fieldKey, "isContract"]}
                          style={{ marginBottom: 0 }}
                          valuePropName="checked"
                        >
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                          />
                        </Form.Item>
                        ម៉ៅ
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} lg={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "performance"]}
                      fieldKey={[fieldKey, "performance"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "ទាមទារបញ្ជូល",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="ការងារអនុវត្តន៍"
                        minLength={0}
                        size="large"
                        allowClear
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col xs={10} lg={12}>
                    <Form.Item
                      {...restField}
                      name={[name, "teamLeaderName"]}
                      fieldKey={[fieldKey, "teamLeaderName"]}
                      rules={[{ required: true, message: "ទាមទារបញ្ជូល" }]}
                    >
                      <Input
                        placeholder="ឈ្មោះមេក្រុម"
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </Form.Item>
                  </Col> */}

                  <Form.Item
                    {...restField}
                    name={[name, "teamNumber"]}
                    fieldKey={[fieldKey, "teamNumber"]}
                    initialValue={index + 1}
                    hidden
                  />

                  <Col xs={8} lg={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "builderCount"]}
                      fieldKey={[fieldKey, "builderCount"]}
                      rules={[{ required: true, message: "ទាមទារបញ្ជូល" }]}
                    >
                      {/* <InputNumber placeholder='ជាង' style={{ width: "100%" }} size='large' /> */}
                      <Select
                        placeholder="👷ជាងសំណង់"
                        style={{ width: "100%" }}
                        size="large"
                      >
                        {countSelectOption(20)}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={8} lg={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "maleWorkerCount"]}
                      fieldKey={[fieldKey, "maleWorkerCount"]}
                      rules={[{ required: true, message: "ទាមទារបញ្ជូល" }]}
                    >
                      <Select
                        placeholder="👨កម្មករប្រុស"
                        style={{ width: "100%" }}
                        size="large"
                      >
                        {countSelectOption(20)}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={8} lg={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "femaleWorkerCount"]}
                      fieldKey={[fieldKey, "femaleWorkerCount"]}
                      rules={[{ required: true, message: "ទាមទារបញ្ជូល" }]}
                    >
                      <Select
                        placeholder="👩កម្មករស្រី"
                        style={{ width: "100%" }}
                        size="large"
                      >
                        {countSelectOption(20)}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={8} lg={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "painterCount"]}
                      fieldKey={[fieldKey, "painterCount"]}
                      rules={[{ required: true, message: "ទាមទារបញ្ជូល" }]}
                    >
                      <Select
                        placeholder="🎨ជាងថ្នាំ"
                        style={{ width: "100%" }}
                        size="large"
                      >
                        {countSelectOption(20)}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={8} lg={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "blacksmithCount"]}
                      fieldKey={[fieldKey, "blacksmithCount"]}
                      rules={[{ required: true, message: "ទាមទារបញ្ជូល" }]}
                    >
                      <Select
                        placeholder="⚙️ជាងដែក"
                        style={{ width: "100%" }}
                        size="large"
                      >
                        {countSelectOption(20)}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={8} lg={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "carpenterCount"]}
                      fieldKey={[fieldKey, "carpenterCount"]}
                      rules={[{ required: true, message: "ទាមទារបញ្ជូល" }]}
                    >
                      <Select
                        placeholder="🪵ជាងឈើ"
                        style={{ width: "100%" }}
                        size="large"
                      >
                        {countSelectOption(20)}
                      </Select>
                    </Form.Item>
                  </Col>

                  {type === "create" ? <Divider /> : null}

                  {type === "edit" ? (
                    <>
                      <Col xs={24} lg={24}>
                        <div
                          style={{
                            fontSize: 16,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 20,
                          }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "isDone"]}
                            fieldKey={[fieldKey, "isDone"]}
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Switch
                              className="is-done-switch"
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                          &nbsp;&nbsp;រួចរាល់
                        </div>
                      </Col>

                      <Col xs={24} lg={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "result"]}
                          fieldKey={[fieldKey, "result"]}
                        >
                          <TextArea
                            placeholder="លទ្ធផល / មូលហេតុ"
                            size="large"
                            allowClear
                          />
                        </Form.Item>
                        <Divider />
                      </Col>
                    </>
                  ) : null}
                </Row>
              </div>
            );
          })}
          <Form.Item>
            <Button
              size="large"
              style={{
                fontSize: 16,
                border: "1px solid #1890ff",
                borderStyle: "dashed",
              }}
              onClick={() => add()}
              block
            >
              +{addButtonTitle}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export default FormListTeam;
