import { Col, DatePicker, Popover, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";

import "./style.css";
import { useRequest } from "ahooks";
import {
  currencyFormat,
  getTotalAccountDashboard,
  getVKExpense,
} from "../../getDatabase";
import LastUpdateIncomeExpense from "./LastUpdateIncomeExpense";
import moment from "moment";
import VKExpenseUpdateDrawer from "./VKExpenseUpdateDrawer";

const { Title } = Typography;

export default function AccountantDashboard() {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState(moment());

  const handleOpenChange = (newOpen) => {
    setOpenDate(newOpen);
  };
  const handleSetDate = (e) => {
    // runFetch({
    //   date: moment(e).format("YYYY-MM-DD")
    // })
    setDate(e);
  };

  const {
    run: runFetch,
    loading: fetchLoading,
    data: VKExpense,
  } = useRequest(getVKExpense, {
    manual: true,
  });

  useEffect(() => {
    runFetch();
  }, []);

  const contentPopover = (
    <div>
      <DatePicker
        value={date}
        size="large"
        onChange={handleSetDate}
        allowClear={false}
      />
    </div>
  );

  return (
    <>
      <VKExpenseUpdateDrawer
        data={VKExpense}
        visible={isOpenUpdate}
        setVisible={setIsOpenUpdate}
        refresh={runFetch}
      />
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        {/* Header ---------------------------------------------------- */}
        <Title
          style={{
            fontFamily: "Moul",
            color: "#1983e6",
            fontWeight: "normal",
          }}
          level={4}
        >
          ចំណូលចំណាយ{" "}
          <Popover
            content={contentPopover}
            placement="bottomLeft"
            trigger="click"
            open={openDate}
            showArrow={false}
            align={{
              offset: [0, -50],
            }}
            onOpenChange={handleOpenChange}
          >
            <span
              style={{
                fontFamily: "KhmerOsSiemreap",
                color: "gray",
                cursor: "pointer",
              }}
            >
              ({moment(date).format("DD-MMM-YYYY")})
            </span>
          </Popover>
          {/* <span
            style={{ fontFamily: "KhmerOsSiemreap", color: "gray" }}
          >{`(ជាង:${totalData?.totalBuilder} | កម្មករ:${totalData?.totalWorker})`}</span> */}
        </Title>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <div
              className="acc-dashboard-card"
              style={{ background: "#5e35b1" }}
              role="button"
              tabIndex={0}
              onClick={() => setIsOpenUpdate(true)}
            >
              <div className="acc-icon">
                <FaArrowTrendDown size={26} color="#FFFFFF" />
              </div>
              <div className="acc-total">
                {currencyFormat(VKExpense?.expenseThisMonth || 0)}
              </div>
              <div className="acc-title">ចំណាយខែនេះ</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <div
              className="acc-dashboard-card"
              style={{
                background: "#1e88e5",
              }}
              role="button"
              tabIndex={0}
              onClick={() => setIsOpenUpdate(true)}
            >
              <div className="acc-icon">
                <FaArrowTrendUp size={26} color="#FFFFFF" />
              </div>
              <div className="acc-total">
                {currencyFormat(VKExpense?.expenseLastMonth || 0)}
              </div>
              <div className="acc-title">ចំណាយខែមុន</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <div>
              <div
                className="acc-dashboard-card"
                style={{
                  background: "#1e88e5",
                  flexDirection: "row",
                  gap: 10,
                  justifyContent: "start",
                  alignItems: "center",
                }}
                role="button"
                tabIndex={0}
                onClick={() => setIsOpenUpdate(true)}
              >
                <div className="acc-icon">
                  <FaArrowTrendDown size={26} color="#FFFFFF" />
                </div>
                <div>
                  <div className="acc-total" style={{ marginTop: 0 }}>
                    {currencyFormat(VKExpense?.expenseThisYear || 0)}
                  </div>
                  <div className="acc-title">ចំណាយឆ្នាំនេះ</div>
                </div>
              </div>
              <div
                className="acc-dashboard-card"
                style={{
                  marginBottom: 0,
                  background: "white",
                  flexDirection: "row",
                  gap: 10,
                  justifyContent: "start",
                  alignItems: "center",
                }}
                role="button"
                tabIndex={0}
                onClick={() => setIsOpenUpdate(true)}
              >
                <div
                  className="acc-icon"
                  style={{ background: "rgb(255,188,0,0.2)" }}
                >
                  <FaArrowTrendUp size={26} color="#ffbc00" />
                </div>
                <div>
                  <div
                    className="acc-total"
                    style={{
                      color: "black",
                      marginTop: 0,
                    }}
                  >
                    {currencyFormat(VKExpense?.expenseLastYear || 0)}
                  </div>
                  <div className="acc-title">ចំណាយឆ្នាំមុន</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ marginTop: 15 }}>
          <LastUpdateIncomeExpense date={moment(date).format("YYYY-MM-DD")} />
        </div>
      </div>
    </>
  );
}
