import React, { useEffect, useState } from "react";
import { Drawer, Row, Col, Form, message, InputNumber } from "antd";
import { Button } from "antd";
import { useRequest } from "ahooks";
import {
  mutateCustomerProfitExpense,
  updateVKExpense,
} from "../../getDatabase";

export default function VKExpenseUpdateDrawer({
  data,
  visible,
  setVisible,
  refresh,
}) {
  const [isMobile, setIsMobile] = useState(false);

  const [form] = Form.useForm();

  const { run, loading } = useRequest(updateVKExpense, {
    manual: true,
    onSuccess: (res) => {
      const isSuccess = res?.data?.toString().trim() === "success";
      if (isSuccess) {
        message.success("បង្តើតជោគជ័យ!!");
        form.resetFields();
        setVisible(false);
        refresh();
      } else {
        message.error("មានបញ្ហា!");
      }
    },
  });

  const handleResize = () => {
    // 960
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = async (values) => {
    run({
      ...values,
      id: data?.vke_id,
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
    }

    return () => {
      form.resetFields();
    };
  }, [visible, form, data]);

  return (
    <Drawer
      width={isMobile ? "100%" : 736}
      title="ចំណូលចំណាយ"
      placement="right"
      onClose={onClose}
      visible={visible}
      bodyStyle={{
        backgroundColor: "#efefef",
      }}
      headerStyle={{
        backgroundColor: "white",
      }}
    >
      <Form
        form={form}
        id="create-customer-form"
        layout="vertical"
        onFinish={onFinish}
      >
        {/* <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="landNumber"
                label="លេខប័ណ្ណដី"
                // rules={[{ required: true, message: "សូមបំពេញលេខប័ណ្ណដី!!" }]}
              >
                <Input placeholder="លេខប័ណ្ណដី" size="large" allowClear />
              </Form.Item>
            </Col>
          </Row> */}

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="expenseThisMonth"
              label="ចំណាយខែនេះ"
              labelAlign="right"
            >
              <InputNumber
                placeholder="##"
                style={{ width: "100%", opacity: 1, color: "black" }}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="expenseLastMonth"
              label="ចំណាយខែមុន"
              labelAlign="right"
            >
              <InputNumber
                placeholder="##"
                style={{ width: "100%", opacity: 1, color: "black" }}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="expenseThisYear"
              label="ចំណាយឆ្នាំនេះ"
              labelAlign="right"
            >
              <InputNumber
                placeholder="##"
                style={{ width: "100%", opacity: 1, color: "black" }}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="expenseLastYear"
              label="ចំណាយឆ្នាំមុន"
              labelAlign="right"
            >
              <InputNumber
                placeholder="##"
                style={{ width: "100%", opacity: 1, color: "black" }}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ flex: 1 }}>
          <Form.Item>
            <Button
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
            >
              បញ្ជូន
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
}
