import { Button, Col, notification, Row } from "antd";
import imageCompression from "browser-image-compression";
import { LoadingOutlined } from "@ant-design/icons";

export const openSuccessNotification = (data) => {
  notification.success({
    message: `${data.title}`,
    description: data.message,
  });
};

export const openErrorNotification = (data) => {
  notification.error({
    message: `${data.title}`,
    description: data.message,
  });
};

export const openWarningNotification = (data) => {
  notification.warning({
    message: ` ${data.title}`,
    description: data.message,
  });
};

export function UploadController({
  children,
  value,
  onChange,
  disabled,
  accept,
  name,
}) {
  return (
    <Button type="dashed" style={{ width: "100%", padding: 0 }}>
      <label htmlFor={name} style={{ width: "100%" }}>
        <input
          value={value}
          accept={accept}
          disabled={disabled}
          style={{ display: "none", width: "100%" }}
          id={name}
          multiple
          type="file"
          onChange={onChange}
        />

        <div style={{ width: "100%" }}>{children}</div>
      </label>
    </Button>
  );
}

export const compressImage = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const compressedFile = await imageCompression(file, options);

  let newFile = new File([compressedFile], `${file?.name?.split(".")[0]}.png`, {
    type: "image/png",
  });

  return newFile;
};

export const getTotalBuilder = (team) => {
  let total = 0;
  team?.forEach((e) => {
    total += parseInt(e?.builderCount);
  });

  return total;
};

export const getTotalPeopleTeam = (team = []) => {
  let total = 0;
  team?.forEach((e) => {
    total += parseInt(e?.builderCount);
    total += parseInt(e?.maleWorkerCount);
    total += parseInt(e?.femaleWorkerCount);
    total += parseInt(e?.painterCount);
    total += parseInt(e?.blacksmithCount);
    total += parseInt(e?.carpenterCount);
  });

  return total;
};

export const getTotalWorker = (team) => {
  let total = 0;
  team?.forEach((e) => {
    total += parseInt(e?.workerCount);
  });

  return total;
};

export const getSumByCategory = (team = []) => {
  let total = 0;
  let totalBuilder = 0;
  let totalMaleWorker = 0;
  let totalFemaleWorker = 0;
  let totalPainter = 0;
  let totalBlacksmit = 0;
  let totalCarpenter = 0;
  team?.forEach((e) => {
    totalBuilder += parseInt(e?.builderCount);
    totalMaleWorker += parseInt(e?.maleWorkerCount);
    totalFemaleWorker += parseInt(e?.femaleWorkerCount);
    totalPainter += parseInt(e?.painterCount);
    totalBlacksmit += parseInt(e?.blacksmithCount);
    totalCarpenter += parseInt(e?.carpenterCount);

    total +=
      parseInt(e?.builderCount) +
      parseInt(e?.maleWorkerCount) +
      parseInt(e?.femaleWorkerCount) +
      parseInt(e?.painterCount) +
      parseInt(e?.blacksmithCount) +
      parseInt(e?.carpenterCount);
  });

  return {
    total,
    totalBuilder,
    totalMaleWorker,
    totalFemaleWorker,
    totalPainter,
    totalBlacksmit,
    totalCarpenter,
  };
};

export const convertUSDtoKHR = (e) => {
  let cash = parseFloat(e) * 4000;
  return cash;
};

export const convertKHRtoUSD = (e) => {
  let cash = parseFloat(e) / 4000;
  return cash;
};

export function currencyFormatKHR(num) {
  num = num > 0 ? parseFloat(num) : 0;
  return (
    <span>
      {num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}&#6107;
    </span>
  );
}

// export function currencyFormatStringKHR(num) {
//   num = num > 0 ? parseFloat(num) : 0;
//   return `${num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
// }

export const checkStatus = (status) => {
  let day;
  switch (status) {
    case "":
      day = 0;
      break;
    case "រុះរើសំណង់ចាស់":
      day = 1;
      break;
    case "ការងារទ្រួសត្រាយដី":
      day = 2;
      break;
    case "ការងារវាស់វែង":
      day = 3;
      break;
    case "រកកែងនៅទីតាំងផ្ទាល់ដី":
      day = 4;
      break;
    case "សំអាតដី":
      day = 5;
      break;
    case "បុកភ្ជើរ":
      day = 6;
      break;
    case "ជីកដីធ្វើគ្រឹះ":
      day = 10;
      break;
    case "លុបបំពេញរណ្តៅគ្រឹះ":
      day = 12;
      break;
    case "ធ្វើធ្នឹមបាតក្រោម":
      day = 15;
      break;
    case "ចាក់ដីលុបបំពេញផ្ទៃអគារ":
      day = 18;
      break;
    case "ធ្វើសសរ":
      day = 20;
      break;
    case "ធ្វើធ្នឹម":
      day = 25;
      break;
    case "បង្គប់ទីប":
      day = 30;
      break;
    case "ចាក់ប្លង់សេ":
      day = 35;
      break;
    case "រៀបឥដ្ឋ":
      day = 40;
      break;
    case "បង្គប់ហ្គែន":
      day = 45;
      break;
    case "ធ្វើជណ្តើរ":
      day = 50;
      break;
    case "បូកនិងសី":
      day = 55;
      break;
    case "ធ្វើដំបូល":
      day = 60;
      break;
    case "ដាក់ទ្វា":
      day = 65;
      break;
    case "ដាក់បង្អួច":
      day = 70;
      break;
    case "ក្រាលការ៉ូ":
      day = 75;
      break;
    case "បំពាក់គ្រឿងបន្ទប់ទឹក ":
      day = 80;
      break;
    case "ចាប់ជ្រីនិងចាប់ជ្រុង":
      day = 85;
      break;
    case "បៀកថ្នាំ":
      day = 90;
      break;
    case "រុញថ្នាំ":
      day = 95;
      break;
    case "បៀករ៉ង់និងសម្អាត":
      day = 100;
      break;
    case "បំពាក់អំពូល":
      day = 100;
      break;
    case "សម្អាត":
      day = 100;
      break;
    case "កែលំអរចំនុចខ្វះខាត":
      day = 100;
      break;
    case "រួចរាល់":
      day = 100;
      break;
    default:
      day = 0;
      break;
  }

  return day;
};

export const AntLoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const BuilderHeader = ({ title }) => {
  return (
    <div className="worker-salary-History">
      <div
        style={{
          background: "#C9C9C9",
          fontWeight: "bold",
          height: "40px",
          width: "100%",
          display: "flex",
          padding: "9px",
          // borderBottom: "2px solid #727272",
        }}
      >
        <p>{title}</p>
      </div>
    </div>
  );
};

export const LabelInputForm = ({ title, form }) => {
  return (
    <Row>
      <Col xs={10} sm={7} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
        {title}
      </Col>
      <Col xs={1} sm={1}>
        :
      </Col>
      <Col
        xs={13}
        sm={16}
        md={{ span: 16 }}
        lg={{ span: 16 }}
        xl={{ span: 16 }}
      >
        {form}
      </Col>
    </Row>
  );
};

export const LabelInputFormModal = ({ title, form }) => {
  return (
    <Row>
      <Col xs={10} sm={7} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
        {title}
      </Col>
      <Col xs={1} sm={1}>
        :
      </Col>
      <Col
        xs={13}
        sm={16}
        md={{ span: 16 }}
        lg={{ span: 16 }}
        xl={{ span: 16 }}
      >
        {form}
      </Col>
    </Row>
  );
};
