export const skillOption = [
  "ឥដ្ឋបូក",
  "ពុម្ភ",
  "ដែក",
  "ជ្រី",
  "ការ៉ូ",
  "ថ្នាំ",
];
export const postionOption = [
  "មេការ",
  "មេក្រុម",
  "ជាង",
  "ជាងថ្នាំ",
  "ជាងពិដាន",
  "ជាងដែក",
  "ជាងឈើ",
  "ជាងដេគ័រ",
  "ប្រុសជំនាញ",
  "ស្រីជំនាញ",
  "កម្មករប្រុស",
  "កម្មករស្រី",
];
export const workStatusOption = [
  "ធម្មតា",
  "ឈឺ",
  "ផ្អាក",
  "សំរាកមួយរយះ",
  "ឈប់",
  "បញ្ឈប់",
];
