import { Button, Col, Row, Typography } from "antd";
import { useState } from "react";
import ImageWatermark from "./ImageWatermark";

function UploadController({
  children,
  value,
  onChange,
  disabled,
  accept,
  name,
}) {
  return (
    <Button size="large">
      <label htmlFor={name} style={{ width: "100%" }}>
        <input
          value={value}
          key={Date.now()}
          accept={accept}
          disabled={disabled}
          style={{ display: "none", width: "100%" }}
          id={name}
          multiple
          type="file"
          onChange={onChange}
        />

        <div style={{ width: "100%" }}>{children}</div>
      </label>
    </Button>
  );
}

const { Title } = Typography;

export default function WaterMark() {
  const [selectedImage, setSelectedImage] = useState([]);

  const onSelectResultImage = async (e) => {
    const images = e.target.files || [];
    images?.forEach((image) => {
      if (image?.type?.split("/")[0] === "image") {
        setSelectedImage((prev) => [...prev, image]);
      }
    });
  };

  const handleReset = () => {
    setSelectedImage([]);
  };

  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Row>
        {/* Header ---------------------------------------------------- */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title
            style={{
              fontFamily: "Moul",
              color: "#1983e6",
              fontWeight: "normal",
            }}
            level={4}
          >
            {`WATERMARK`}
          </Title>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {/* <Button size="large">បញ្ចូលរូបភាព</Button> */}
          <UploadController
            name="resultImageAdd"
            accept="image/png, image/jpeg, image/jpg"
            onChange={onSelectResultImage}
          >
            បញ្ចូលរូបភាព
          </UploadController>
          <Button
            size="large"
            
            style={{ marginLeft: 15 }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <div>
        {selectedImage.length <= 0 && (
          <div style={{ fontSize: 20, marginTop: 20 }}>សូមបញ្ជូលរូបភាព</div>
        )}
        {selectedImage.length > 0 &&
          selectedImage?.map((load, index) => (
            <ImageWatermark key={index} index={index} selectedImage={load} />
          ))}
      </div>
    </div>
  );
}
