export function keyMenu(e) {

    const myArr = e.split("/");
    let x = ""

    if (myArr[1] === 'customer') {
        x = "/customer"
    }
    else if (myArr[1] === 'report') {
        x = "/report"
    }

    else if (myArr[1] === 'pretty_cash') {
        x = "/pretty_cash"
    } else if (myArr[1] === 'biography') {
        x = "/biography"
    }

    else if (myArr[1] === 'requesting') {
        x = "/requesting"
    }

    else if (myArr[1] === 'builder') {
        x = "/builder"
    }

    else if (myArr[1] === 'watermark') {
        x = "/watermark"
    }

    else if (myArr[1] === 'users') {
        x = "/users"
    }

    else if (myArr[1] === 'accountant') {
        x = "/accountant"
    }

    else {
        x = "/"
    }

    return x
}