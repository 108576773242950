import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form, DatePicker, Select } from "antd";
import { Typography, Button } from "antd";
import moment from "moment";
import { getConstructByDate, getCustomers } from "../../getDatabase";
import PrintReportContent from "./PrintReportContent";
import { useRequest } from "ahooks";
import { useReactToPrint } from "react-to-print";

const { Title } = Typography;
const { Option } = Select;
export default function PrintReport() {
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [date, setDate] = useState(moment());
  const printRef = useRef();

  const {
    data: dailyConstructions,
    loading,
    run,
  } = useRequest(getConstructByDate, {
    defaultParams: [moment(date).format("YYYY-MM-DD"), customerId],
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    const fetchCustomers = async () => {
      const customer = await getCustomers();
      setCustomers(customer);
    };
    fetchCustomers();
  }, []);

  useEffect(() => {
    run(date, customerId);
  }, [date, customerId]);

  const handleSelectDate = (e) => {
    if (e) {
      setDate(moment(e));
    }
  };

  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Row>
        {/* Header ---------------------------------------------------- */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title
            style={{
              fontFamily: "Moul",
              color: "#1983e6",
              fontWeight: "normal",
            }}
            level={4}
          >
            {`បោះពុម្ភរបាយការណ៍`}
          </Title>
          <Form form={form} id="report-filter-form">
            <Row style={{ marginBottom: 10 }}>
              <Col xs={24} sm={24} md={6} lg={5} xl={6} style={{ padding: 5 }}>
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={"កាលបរិច្ឆេទ"}
                  allowClear={false}
                  value={date}
                  onChange={(e) => handleSelectDate(e)}
                />
              </Col>

              <Col xs={24} sm={24} md={6} lg={5} xl={3} style={{ padding: 5 }}>
                <Select
                  placeholder="ការដ្ឋាន"
                  size="large"
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  onChange={(e) => setCustomerId(e)}
                >
                  <Option value={""} key="all">
                    --ទាំងអស់--
                  </Option>
                  {customers?.map((customer) => (
                    <Option key={customer.c_id} value={customer.c_id}>
                      {customer.constructionName}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col xs={24} sm={24} md={4} lg={5} xl={3} style={{ padding: 5 }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  size="large"
                  onClick={handlePrint}
                >
                  Print
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <PrintReportContent
            dataSource={dailyConstructions?.data}
            printRef={printRef}
            isLoading={loading}
          />
        </Col>
      </Row>
    </div>
  );
}
