import { Col, Row, Spin } from "antd";
import moment from "moment";
import React from "react";
import { BsBox } from "react-icons/bs";
import {
  AntLoadingIcon,
  getSumByCategory,
  getTotalBuilder,
  getTotalWorker,
} from "../../own-comp";
import { getOtherBulders } from "../../util/function";

import "./PrintReportContent.css";
import Teams from "./Teams";

function TmrTeamRow({ dataSource: e }) {
  return (
    <Row key={e?.dtmr} className="row-tmr-team">
      <Col
        xs={5}
        style={{
          display: "inline-block",
          fontWeight: "bold",
        }}
      >
        - ក្រុម{e?.teamNumber} (ជ{e?.builderCount || 0} ក{e?.workerCount || 0})
      </Col>
      {/* <Col xs={1}>:</Col> */}
      <Col xs={19} style={{ wordWrap: "break-word" }}>
        : {e?.performance}
      </Col>
    </Row>
  );
}

function PrintReportContent({ dataSource, printRef, isLoading }) {
  if (isLoading)
    return (
      <div className="print-report-container loading-container">
        <div className="loading-data">
          <Spin indicator={AntLoadingIcon} />
        </div>
      </div>
    );

  if (dataSource?.length <= 0)
    return (
      <div className="print-report-container loading-container">
        <div className="loading-data">
          <BsBox size={30} />
          <h3>No data</h3>
        </div>
      </div>
    );

  return (
    <div className="print-report-container" ref={printRef}>
      {[...dataSource]?.map((load) => {
        const teams = load?.performances || [];
        // const tmr_team = load?.team_tmr || [];

        const sumByCat = getSumByCategory(teams);
        const totalBuilder =
          sumByCat?.totalBlacksmit +
          sumByCat?.totalCarpenter +
          sumByCat?.totalBuilder +
          sumByCat?.totalPainter;
        const totalWorker =
          sumByCat?.totalMaleWorker + sumByCat?.totalFemaleWorker;

        return (
          <table className="report-table-print">
            <tbody>
              <tr>
                <td colSpan={6} className="text-center construction-name">
                  ការដ្ឋាន៖ {load?.constructionName}
                </td>
                <td colSpan={3} className="text-center construction-date">
                  <div>{moment(load?.createdAt).format("DD-MM-YY")}</div>
                </td>
              </tr>
              {/* header row */}
              <tr className="row-header">
                <td className="text-center">ក្រុម</td>
                <td className="text-center">ប្រភេទការងារ</td>
                <td className="text-center">ជាងឈើ</td>
                <td className="text-center">ជាងដែក</td>
                <td className="text-center">ជាងថ្នាំ</td>
                <td className="text-center">ជាងសំណង់</td>
                <td className="text-center">កម្មករប្រុស</td>
                <td className="text-center">កម្មករស្រី</td>
                <td className="text-center">លទ្ទផល</td>
              </tr>

              {teams?.map((team) => (
                <tr key={team?.dt_id}>
                  <td className="text-center">{team?.teamNumber}</td>
                  <td>{team?.performance}</td>
                  <td className="text-right">{team?.carpenterCount}</td>
                  <td className="text-right">{team?.blacksmithCount}</td>
                  <td className="text-right">{team?.painterCount}</td>
                  <td className="text-right">{team?.builderCount}</td>
                  <td className="text-right">{team?.maleWorkerCount}</td>
                  <td className="text-right">{team?.femaleWorkerCount}</td>
                  <td>{team?.result}</td>
                </tr>
              ))}

              <tr>
                <td
                  colSpan={6}
                  rowSpan={3}
                  style={{ borderTopStyle: "double" }}
                />
                <td colSpan={3} style={{ borderTopStyle: "double" }}>
                  <div className="space-between">
                    <b>ជាង</b>
                    <div>{totalBuilder}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <div className="space-between">
                    <b>កម្មករ</b>
                    <div>{totalWorker}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <div className="space-between">
                    <b>សរុប</b>
                    <div>{sumByCat?.total}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        );
      })}
    </div>
  );

  return (
    <div className="print-report-container" ref={printRef}>
      {[...dataSource]?.map((load) => {
        const teams = load?.performances || [];
        const tmr_team = load?.team_tmr || [];
        return (
          <Row key={load?.dc_id} style={{ marginBottom: 20 }} className="item">
            <Col xs={24} md={24} className="header-row">
              <Row style={{ width: "60%" }} className="khmer-moul">
                <Col xs={5} md={5}>
                  ការដ្ឋាន
                </Col>
                <Col xs={19} md={19}>
                  : {load?.constructionName}
                </Col>
              </Row>
              <div style={{ fontWeight: "bold" }}>
                {moment(load?.createdAt).format("DD-MM-YYYY")}
              </div>
            </Col>
            <Col xs={24} md={24} className="header-row border-top">
              <div className="header-summary">
                {load?.chiefName} | ជាង:{" "}
                <b>
                  {getTotalBuilder(load?.performances) -
                    parseInt(load?.painterCount)}
                </b>{" "}
                | ថ្នាំ: <b>{load?.painterCount}</b> | ប្រុស:{" "}
                <b>
                  {getTotalWorker(load?.performances) -
                    parseInt(load?.femaleWorkerCount)}
                </b>{" "}
                | ស្រី: <b>{load?.femaleWorkerCount}</b>
              </div>
            </Col>
            <Col
              xs={{ span: 21, offset: 3 }}
              md={{ span: 21, offset: 3 }}
              className="teams"
            >
              {teams?.map((team) => (
                <Teams key={team.dt_id} team={team} />
              ))}
              {getOtherBulders(load) !== "" ? (
                <Row
                  style={{
                    fontSize: "15px",
                    background: "#EEEEEE",
                  }}
                >
                  <Col xs={6} style={{ padding: 10, fontWeight: "bold" }}>
                    ជាងផ្សេងៗ
                  </Col>
                  <Col xs={18} style={{ padding: 10 }}>
                    : {getOtherBulders(load)}
                  </Col>
                </Row>
              ) : null}

              {load?.challenges !== "" ? (
                <Row className="other-problem">
                  <Col xs={6} style={{ padding: 10, fontWeight: "bold" }}>
                    បញ្ហាផ្សេងៗ
                  </Col>
                  <Col xs={18} style={{ padding: 10 }}>
                    : {load?.challenges}
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col xs={24} className="header-tmr-team">
                  ការងារថ្ងៃស្អែក:
                </Col>
                <Col xs={24}>
                  {tmr_team?.map((e) => (
                    <TmrTeamRow dataSource={e} key={e?.dtmr_id} />
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

export default PrintReportContent;
