export const expenseNameList = [
  { label: "រុះរើ (សំណង់ចាស់)", value: "រុះរើ (សំណង់ចាស់)" },
  { label: "ជាងសំណង់", value: "ជាងសំណង់" },
  { label: "គ្រឿងសំណង់", value: "គ្រឿងសំណង់" },
  { label: "សម្ភារៈជាងសំណង់", value: "សម្ភារៈជាងសំណង់" },
  { label: "ជាងថ្នាំ", value: "ជាងថ្នាំ" },
  { label: "គ្រឿងថ្នាំ", value: "គ្រឿងថ្នាំ" },
  { label: "សម្ភារៈជាងថ្នាំ", value: "សម្ភារៈជាងថ្នាំ" },
  { label: "ជាងដែក", value: "ជាងដែក" },
  { label: "គ្រឿងជាងដែក", value: "គ្រឿងជាងដែក" },
  { label: "សម្ភារៈជាងដែក", value: "សម្ភារៈជាងដែក" },

  { label: "ទិញឧបករណ៍", value: "ទិញឧបករណ៍" },
  { label: "ជួសជុល", value: "ជួសជុល" },
  { label: "Petty Cash", value: "Petty Cash" },
  { label: "ថែទាំ", value: "ថែទាំ" },
  { label: "សំអាត", value: "សំអាត" },
  { label: "សាំង", value: "សាំង" },
  { label: "ប្រាក់បៀវត្តន៍ & អត្ថប្រយោជន៍ផ្សេងៗ", value: "ប្រាក់បៀវត្តន៍ & អត្ថប្រយោជន៍ផ្សេងៗ" },
  { label: "ពន្ធ & សេវាកម្មពន្ធ", value: "ពន្ធ & សេវាកម្មពន្ធ" },
  { label: "អជ្ញារប័ណ្ណ", value: "អជ្ញារប័ណ្ណ" },
  { label: "ធ្វើប្លង់ & ព្រីនឯកសារ", value: "ធ្វើប្លង់ & ព្រីនឯកសារ" },
  { label: "ជួលផ្ទះ & ជួលសំភារៈ", value: "ជួលផ្ទះ & ជួលសំភារៈ" },
  { label: "អាហារ ភេសផ្ជៈ និងកម្មវិធីផ្សេងៗ", value: "អាហារ ភេសផ្ជៈ និងកម្មវិធីផ្សេងៗ" },
  { label: "ដឹកជញ្ជូន", value: "ដឹកជញ្ជូន" },
  { label: "ថ្លៃពេទ្យ & ធានារ៉ាប់រង", value: "ថ្លៃពេទ្យ & ធានារ៉ាប់រង" },
  { label: "កម្មសិក្សា & ប្រឹក្សាយោបល់", value: "កម្មសិក្សា & ប្រឹក្សាយោបល់" },
  { label: "ផ្សេងៗ", value: "ផ្សេងៗ" },
  { label: "ផ្លូវថ្នល់", value: "ផ្លូវថ្នល់" },

];
