import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function ExpenseFormList({ name, isWritable }) {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => {
            return (
              <div key={key}>
                {/* <Row gutter={10}>
                  <Col xs={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "rgb(135, 187, 237,0.6)",
                        padding: "6px 10px",
                        marginBottom: 25,
                      }}
                    >
                      <div style={{ fontSize: 20 }}>
                        <AiFillCloseCircle
                          style={{ fontSize: 18, color: "red" }}
                          className="remove-class zoom-bigger"
                          onClick={() => remove(name)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row> */}

                <Row gutter={10}>
                  <Col xs={12} lg={12}>
                    <Form.Item
                      {...restField}
                      label="Expense name"
                      name={[name, "expense_name"]}
                      fieldKey={[fieldKey, "expense_name"]}
                      //   hidden
                    >
                      <Input
                        placeholder="Expense name"
                        style={{ width: "100%", opacity: 1, color: "black" }}
                        size="medium"
                        disabled
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} lg={12}>
                    <Form.Item
                      {...restField}
                      label="Expense"
                      name={[name, "expense"]}
                      fieldKey={[fieldKey, "expense"]}
                    >
                      <InputNumber
                        placeholder="##"
                        style={{ width: "100%", opacity: 1, color: "black" }}
                        size="medium"
                        disabled={!isWritable}
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col xs={1} lg={1}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col> */}
                </Row>
              </div>
            );
          })}
          {/* <Row gutter={10}>
            <Col xs={11}>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  បន្ថែម
                </Button>
              </Form.Item>
            </Col>
            <Col xs={13}></Col>
          </Row> */}
        </>
      )}
    </Form.List>
  );
}

export default ExpenseFormList;
